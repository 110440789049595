import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import home from "../../statics/img/home.svg";
import Logo from "../../statics/img/Alexandra_Dumitrescu_Logo.png";

const Navi = (props) => {
  const [state, setState] = useState({
    pagedata: props.block,
    navi: "inactive",
  });

  const toggleNavi = () => {
    if (state.navi === " active") {
      setState((prev) => ({
        ...prev,
        navi: " inactive",
      }));
    } else {
      setState((prev) => ({
        ...prev,
        navi: " active",
      }));
    }
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      navi: "",
      pagedata: props.block,
    }));
  }, [props]);

  const onlyMainNav = (data, key) => {
    if (data.Hauptnavigation) {
      return (
        <li key={key}>
          <Link to={"/" + data.Titel_slug}>{data.Titel}</Link>
        </li>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="headerHelper">
      <Link to="/" className={`logoLink ${state.navi}`}>
        <img src={Logo} alt="Alexandra Dumitrescu" className="logo" />
        <div className="claim">
          Personal- und
          <br />
          Organisationsentwicklung,
          <br />
          Coaching, Supervision
        </div>
      </Link>
      <nav className={`${state.navi}`}>
        <button
          onClick={() => toggleNavi()}
          className={"NaviButton" + state.navi}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <Link to="/" className={`homeLink ${state.navi}`}>
          <img src={home} alt="Startseite" />
        </Link>
        <ul className={"Navi" + state.navi}>
          {state.pagedata &&
            state.pagedata.map((item, key) => onlyMainNav(item, key))}
        </ul>
      </nav>
    </div>
  );
};

export default Navi;
