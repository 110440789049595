import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { APIURL, TOKEN } from "../../utils/apiData";
import Datepicker from "../Form/Datepicker";
import { nanoid } from "nanoid";

import "./Terminplaner.scss";

const Terminplaner = (props) => {
  const settings = props.block.settings;
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    id: nanoid(8),
  });

  const editFormData = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getDate = (props) => {
    setFormData((prev) => ({
      ...prev,
      date: props,
    }));
  };

  const getTermine = (props) => {
    setFormData((prev) => ({
      ...prev,
      time: props,
    }));
  };

  const submitForm = () => {
    fetch(APIURL + "/api/forms/submit/termine?token=" + TOKEN, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        form: formData,
      }),
    });
    fetch(
      APIURL + "/api/collections/save/" + settings.database + "?token=" + TOKEN,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: formData,
        }),
      }
    )
      .then((res) => res.json())
      .then(() => {
        if (settings.forward) {
          document.body.scrollTop = 0;
          const url = "/" + settings.forward.display;
          document.body.scrollTop = 0;
          history.push(url);
          document.body.scrollTop = 0;
        }
      });
  };

  const Step1 = () => {
    return (
      <>
        <section id="step1" className="step">
          <select
            name="anrede"
            id="anrede"
            required
            value={formData.anrede || ""}
            onChange={editFormData}
          >
            <option value="">Anrede*</option>
            <option value="Herr">Herr</option>
            <option value="Frau">Frau</option>
          </select>
          <label htmlFor="vorname">
            <input
              type="text"
              name="vorname"
              id="vorname"
              placeholder="Vorname*"
              required
              value={formData.vorname || ""}
              onChange={editFormData}
            />
          </label>
          <label htmlFor="nachname">
            <input
              type="text"
              name="nachname"
              placeholder="Nachname*"
              id="nachname"
              required
              value={formData.nachname || ""}
              onChange={editFormData}
            />
          </label>
          <button
            className="next-button Button"
            onClick={(e) => {
              const isValidAnrede = document
                .getElementById("anrede")
                .checkValidity();
              const isValidVorname = document
                .getElementById("vorname")
                .checkValidity();
              const isValidNachname = document
                .getElementById("nachname")
                .checkValidity();
              if (isValidAnrede && isValidVorname && isValidNachname) {
                e.preventDefault();
                setStep(2);
              }
            }}
          >
            Termin auswählen
          </button>
        </section>
        <section>* Pflichtfeld</section>
      </>
    );
  };

  const Step2 = () => {
    return (
      <section id="step2">
        <Datepicker
          block={props.block}
          loadTimes="true"
          date={getDate}
          termine={getTermine}
        />
        {formData.date && formData.time && (
          <button
            className="next-button Button"
            onClick={(e) => {
              e.preventDefault();
              setStep(3);
            }}
          >
            Reservieren
          </button>
        )}
      </section>
    );
  };

  const Step3 = () => {
    const d = new Date(formData.date + "T" + formData.time + ":00.000");
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const date = d.toLocaleDateString("de-DE", options);
    return (
      <>
        <section>
          <div className="currentState">
            {formData.anrede} {formData.vorname} {formData.nachname}
          </div>
          <div className="currentState">Ihr Termin: {date}</div>
          <label htmlFor="telefon">
            <input
              type="tel"
              name="telefon"
              id="telefon"
              placeholder="Telefon*"
              pattern="^[0-9-+\s()]*$"
              required
              value={formData.telefon || ""}
              onChange={editFormData}
            />
          </label>
          <label htmlFor="email">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="E-Mail Adresse*"
              required
              value={formData.email || ""}
              onChange={editFormData}
            />
          </label>
          <label htmlFor="nachricht">
            <textarea
              name="nachricht"
              id="nachricht"
              placeholder="Ihre Nachricht..."
              value={formData.nachricht || ""}
              onChange={editFormData}
            ></textarea>
          </label>
          <label htmlFor="datenschutz" className="datenschutz-label">
            <input
              type="checkbox"
              required
              value="Datenschutz akzeptiert"
              name="datenschutz"
              id="datenschutz"
              onChange={editFormData}
            />
            <span
              dangerouslySetInnerHTML={{ __html: settings.datenschutz }}
              className="datenschutzP"
            />
          </label>

          <button
            className="next-button Button"
            onClick={(e) => {
              const isValidTelefon = document
                .getElementById("telefon")
                .checkValidity();
              const isValidEmail = document
                .getElementById("email")
                .checkValidity();
              const isValidDatenschutz = document
                .getElementById("datenschutz")
                .checkValidity();
              if (isValidTelefon && isValidEmail && isValidDatenschutz) {
                e.preventDefault();
                submitForm();
              }
            }}
          >
            Reservieren
          </button>
        </section>
        <section>* Pflichtfeld</section>
      </>
    );
  };

  return (
    <form id="terminplaner">
      {step === 1 && Step1()}
      {step === 2 && Step2()}
      {step === 3 && Step3()}
    </form>
  );
};

export default Terminplaner;
